import React from 'react';
import itunes from '../images/badges/itunes.svg';
import amazon from '../images/badges/amazon.png';
import books from '../images/badges/books.svg';

const Icon = props => {

  let icon, imgStyle
  let style = { cursor: 'pointer', margin: '7px 10px', display: 'flex' }
  switch (props.type) {
    case 'apple':
      icon = books
      break;
    case 'amazon':
      icon = amazon
      style = { 
        width: '141px',
        background: 'black',
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: '5px',
        padding: '5px',
        cursor: 'pointer',
        margin: '7px 10px'
      }
      imgStyle={ width: '100%' }
      break;
    case 'itunes':
      icon = itunes
      break;
    default:
      break;
  }

  return (
    <div style={style}>
      <a href={props.link} target='_blank'>
        <img style={imgStyle} src={icon} alt={'badge'} />
      </a>
    </div>
  )

}

export default Icon