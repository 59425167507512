import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Icon from '../components/Icon';
import BookCover from '../components/BookCover';
import CdCover from '../components/CdCover';

const amazonLink = 'https://www.amazon.com/I-Can-Spell-My-Colors-ebook/dp/B08P4XVCY7/ref=sr_1_2?dchild=1&keywords=I+can+Spell+my+colors&qid=1608236518&sr=8-2'
const appleLink = 'https://books.apple.com/us/book/i-can-spell-my-colors/id1539418411?itsct=books_toolbox&itscg=30200&ct=books_i_can_spell_my_colors&ls=1'
const musicLink = 'http://itunes.apple.com/album/id/1544601178?ls=1&app=itunes'
const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const json = props.data.allFeaturesJson.edges;
  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content="Discover this fun musical eBook and music album with 13 songs to teach your child to spell the color words."
        />
        <meta name="keywords" content="Educational Book, Children’s book, Children’s eBook, Kid’s book, Interactive Children’s Book, Learn to Spell for Toddlers" />
        <meta name="author" content="Barbara Ausere Wood" />
      </Helmet>
      <div className="intro pb-2">
        <div className="container">
          <div className='row'>
            <div className='col-lg-4 col-2'></div>
            <div className='col-lg-8 col-9'>
              <h2>I Can Spell</h2>
              <h1 style={{color:'', letterSpacing: '.1em'}}>My Colors</h1>
              <p className='pb-0 mb-0'>
                Discover this fun musical eBook and music album 
              </p>
              <p className='pb-1'>
                with 13 songs to teach your child to spell the color words.
              </p>
              <p style={{fontSize: 22, color: 'red', fontWeight: 700, marginBottom: 0}}>ORDER NOW!</p>
              <div className='icons pb-2'>
                <Icon type='apple' link={appleLink}/>
                <Icon type='itunes' link={musicLink}/>
                <Icon type='amazon' link={amazonLink}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='music-notes-background'>
        <div className="container pt-4">
          <div className="row justify-content-center">
            <div className='col-6 mb-4'>
              <BookCover />
              {/* <img className='book-cover' src={bookCover} alt='Book Cover' /> */}
            </div>
            <div className='book-description col-lg-6 col-md-12 pl-5'>
              <p className='text-align-center'>
                <i><strong style={{fontSize: '1.2rem', color: '#119cbd'}}>I Can Spell My Colors</strong></i> is a fun interactive children's musical eBook with 13 songs and illustrations 
                to motivate your child to learn to spell the color words. It is the perfect educational resource for 
                both parents and teachers.
              </p>
              <p>
                It is intended to teach children in pre-K, kindergarten, first and second 
                grades, and home schools, as well as children with learning disabilities and students learning English 
                as a second language. In fact, <i>I Can Spell My Colors</i> is sure to delight learners of any age and 
                background!
              </p>
              <p>
                Children and adults alike will enjoy the nostalgic illustrations reminiscent of the 
                well-known Dick and Jane readers and Little Golden Books. Familiar nursery rhyme arrangements make 
                learning the tunes and lyrics quick and easy. 
              </p>
              <p>
                <i>I Can Spell My Colors</i> is available as a musical digital 
                eBook or as a Music Album. With 30 colorful pages (including covers) and approximately 30 minutes of vocal and instrumental 
                arrangements (sheet music included in eBook), your child will learn and be entertained as they sing 
                along with these catchy tunes. 
              </p>
              <p>
                Learning to spell has never been so much fun! 
              </p>
            </div>
          </div>
        </div>

      </div>
      <div className='dots-background'>
        <div className="container pt-4 pb-4 ">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <h2 className="title-3 text-dark mb-4">Listen Now</h2>
              <p className='mb-3'>Click an image to listen to the corresponding song from I Can Spell My Colors music album!</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} className="col-lg-6 col-md-12 pb-2">
              <CdCover />
            </div>
            {json.map(edge => (
              <a key={edge.node.id} className="anchor-feature col-12 col-md-5 col-lg-3 mb-2" href={edge.node.url}>
                <div style={{ backgroundImage: `url(${edge.node.image})` }} className="feature">
                  <h2 style={{color: edge.node.title === 'White' ? 'black' : edge.node.title}} className="feature-title">{edge.node.title}</h2>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>

      <div>
        <div className="container pt-4 pb-4">
          <div className="row justify-content-center">
            <div className='col-12'>
              <h2 className="title-3 text-dark mb-3">Reviews</h2>
            </div>
            <div className='col-12 col-md-6 mb-1'>
              <div className="testimonial">
                <div className="testimonials-meta">
                  <h2 className="testimonials-title">Katie A.</h2>
                  <p className="testimonials-jobtitle">Homeschool Mom</p>
                </div>
                <div className="testimonials-content">
                  <blockquote>
                    <p>
                      As a homeschool mom, I really appreciate when an author puts so much effort 
                      into developing exceptional learning materials! My 3-year-old, who is learning 
                      how to read, loves the catchy songs with tunes she already knows, as well as 
                      the simple pictures that help her associate the words of the song with the color. 
                      This program is helping her get even better at putting words together. 
                      I give it a high recommendation for both teachers and homeschoolers, plus any 
                      parent who wants to help their child have fun while easily advancing their 
                      spelling skills.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 mb-1'>
              <div className="testimonial">
                <div className="testimonials-meta">
                  <h2 className="testimonials-title">Rachel B.</h2>
                  <p className="testimonials-jobtitle">Teacher</p>
                </div>
                <div className="testimonials-content">
                  <blockquote>
                    <p>
                      I Can Spell my Colors by Barbara Wood is a fantastic book that teaches children 
                      how to spell their colors using familiar tunes. The book is filled with fun 
                      pictures and easy to read text as well as a soundtrack to go along with it. 
                      As a First Grade teacher, I love using the book in my classroom to help children 
                      learn to spell the different colors. The pictures in the book are colorful and 
                      helped my students have a visual connection with each color it was referring to. 
                      The songs are interactive and can easily be put to actions. After listening to the 
                      songs as we read the book, I began to use the songs in my classroom as my students 
                      transitioned from different subjects and activities to another. I like using music 
                      in the classroom to teach a concept and I Can Spell My Colors did that for me. I 
                      will continue to use this as I have seen children from a very young age learn and 
                      read the colors. I am thrilled with this book and will continue to use it in my 
                      classroom as well as with my own children.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className='yellow-background'>
        <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
          <div className="row justify-content-start">
            <div className="col-12">
              <h2 className="title-3 text-dark mb-3">Our Activities</h2>
            </div>
            {markdown.map(edge => (
              <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1"
                style={{    
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <div className="card service service-teaser">
                  <div className="card-content">
                    <Link to={edge.node.frontmatter.path}>
                      <img src={edge.node.frontmatter.image} data-pin-nopin="true" />
                      <h2 style={{
                        width: '100%',
                        height: '40px',
                        lineHeight:'44px',
                        textAlign:'center',
                        backgroundColor:'#119cbdb5'
                      }}>{edge.node.frontmatter.title}</h2>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-12 text-center">
              <Link className="button button-primary mt-2" to="/activities">
                View All Activities
              </Link>
            </div>
          </div>
        </div>
      </div>

      
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/activities/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            image
          }
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          image
          url
        }
      }
    }
  }
`;

export default Home;
