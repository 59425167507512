import React from 'react';
import Cover from '../images/cd-cover.png';

import '../scss/components/cd-cover.scss'

const CdCover = () => {
  return (
    <article class="cd case">
      <div>
        <div class="cd-img">
          <span><img src={Cover} alt='cd cover'/></span>
        </div>
      </div>
    </article>
  )
}

export default CdCover;