import React from 'react';
import Cover from '../images/front-cover.jpg'

import '../scss/components/book-cover.scss'

const BookCover = () => {
  return (
    <div className="contain">
      <div className="book">
        <div className="inner-book">
          <div className="book-img" style={{paddingTop: 'calc(1.07 * 100%)'}}>
            <img src={Cover} alt='eBook cover of I Can Spell My Colors interactive learning book for children' />
          </div>
          <div className="book-page"></div>
          <div className="book-page book-page-2"></div>
          <div className="book-page book-page-3"></div>
          <div className="book-img final-page" style={{paddingTop: 'calc(1.07 * 100%)'}}>
            <img src={Cover} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookCover;